import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentButtons from '@solid-ui-components/ContentButtons'
import { Script } from "gatsby"
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { Button, Link } from 'theme-ui'

const Calendly = ({
  ...props
}) => {
  
  const openCalendly = () => {
    window.Calendly.initPopupWidget({url: 'https://calendly.com/alexandru-ops-ai/30min'});
    return false;
  }

  const text = [
    {
      "text": "Ready to Scale Your Development?",
      "color": "alpha",
      "variant": "h4"
    },
    {
      "text": "See how enterprise teams use Toggly to manage feature releases at scale. Book a personalized demo with our team."
    },
    {
      "text": "Perfect for teams that need:",
      "variant": "small",
      "color": "omega"
    }
  ];

  const features = [
    {
      "text": "• Enterprise SSO & Role-Based Access",
      "variant": "small",
      "color": "omega"
    },
    {
      "text": "• Custom Deployment Strategies",
      "variant": "small",
      "color": "omega"
    },
    {
      "text": "• Advanced Analytics & Monitoring",
      "variant": "small",
      "color": "omega"
    }
  ];

  return (
    <Container>
      <ContentContainer variant='cards.paper'>
        <Flex
          sx={{
            flexDirection: [`column`, `row`],
            justifyContent: `space-between`,
            alignItems: [`center`, `flex-start`],
            textAlign: [`center`, `left`],
            gap: `30px`
          }}
        >
          <Box sx={{ flex: 1 }}>
            <ContentText content={text} />
            <Box sx={{ mt: 3 }}>
              <ContentText content={features} />
            </Box>
          </Box>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: '10px',
            alignItems: [`center`, `flex-start`]
          }}>
            <Button 
              onClick={openCalendly} 
              variant={'primary'}
              sx={{
                fontSize: 2,
                px: 4,
                py: 3
              }}
            >
              Schedule Enterprise Demo
            </Button>
            <Link 
              href="https://app.toggly.io/register" 
              variant="accent"
              sx={{ 
                textDecoration: 'none',
                fontSize: 1
              }}
            >
              or start with our free plan →
            </Link>
          </Box>
        </Flex>
      </ContentContainer>
    </Container>
  );
}

export default Calendly
