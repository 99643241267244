import React from 'react'
import { Container, Flex, Box, Badge, Link } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

import { FaRegClock } from 'react-icons/fa'

const styles = {
  wrapper: {
    flex: [`100%`, null, null, 1],
    minWidth: 300,
    maxWidth: [`none`, null, null, 500],
    cursor: `pointer`,
    p: 3
  },
  card: {
    overflow: `hidden`,
    height: `full`,
    width: `full`,
    bg: `white`,
    borderRadius: `lg`,
    boxShadow: `default`,
    transition: `all 0.2s ease-in-out`,
    ':hover': {
      transform: `translateY(-5px)`,
      boxShadow: `hover`
    }
  },
  content: {
    alignItems: `stretch`,
    flexDirection: `column`,
    height: `full`
  },
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`,
    flex: 1,
    p: 4
  },
  footerWrapper: {
    alignItems: `center`,
    mt: 3,
    pt: 3,
    borderTop: `1px solid`,
    borderColor: `omegaLighter`
  },
  postInfo: {
    flex: 1,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    color: `omega`,
    ml: 3
  },
  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: 200,
    overflow: `hidden`,
    img: {
      height: `100%`,
      width: `100%`,
      objectFit: `cover`
    }
  },
  image: {
    display: `block`,
    height: `full`,
    bg: `omegaLighter`,
    borderRadius: `default`,
    minHeight: `15rem`,
    div: {
      p: `0 !important`
    }
  },
  avatar: {
    size: 42,
    bg: `omegaLighter`,
    borderRadius: `full`,
    borderStyle: `solid`,
    borderWidth: `md`,
    borderColor: `omegaLighter`,
    boxSizing: `content-box`,
    img: {
      objectPosition: 'top center !important'
    }
  }
}

const BlogBlock01 = ({ content: { text, collection, buttons } }) => (
  <Container>
    <Box sx={{ textAlign: `center` }}>
      <ContentText content={text} />
    </Box>
    <Divider />
    {collection && (
      <Reveal effect='fadeIn'>
        <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -3 }}>
          {collection.map(
            ({ container, text, images, avatar, buttons }, index) => (
              <Box key={`item-${index}`} sx={styles.wrapper}>
                <ContentContainer
                  content={container}
                  variant='cards.interactive'
                  sx={styles.card}
                >
                  <Flex as='article' sx={styles.content}>
                    {/* Image */}
                    {images && images[0] && (
                      <Box sx={styles.imageWrapper}>
                        <ContentImages
                          content={{ images }}
                          sx={styles.image}
                          imageEffect='fadeIn'
                        />
                      </Box>
                    )}
                    <Box sx={styles.body}>
                      {/* Category */}
                      {text?.[0]?.text && (
                        <Box sx={{ display: `inline-block` }}>
                          <Box mb='3'>
                            <Badge
                              variant='tag'
                              sx={{ 
                                bg: `alphaLighter`,
                                color: `alpha`,
                                fontSize: 1,
                                px: 3,
                                py: 1,
                                borderRadius: `full`
                              }}
                            >
                              {text[0].text}
                            </Badge>
                          </Box>
                        </Box>
                      )}
                      {/* Title */}
                      <ContentText
                        content={text?.[1]}
                        sx={{ 
                          flex: [0, 0, `auto`],
                          fontSize: 3,
                          fontWeight: `bold`,
                          color: `omegaDarker`,
                          mb: 3
                        }}
                      />
                      {/* Excerpt */}
                      <ContentText
                        content={text?.[2]}
                        variant='small'
                        sx={{ 
                          flex: `auto`,
                          mb: 3,
                          color: `omega`,
                          lineHeight: `relaxed`
                        }}
                      />
                      {/* Footer */}
                      <Box sx={{ variant: `text.small` }}>
                        <Flex sx={styles.footerWrapper}>
                          {avatar && (
                            <ContentImages
                              content={{ images: [avatar] }}
                              sx={styles.avatar}
                              imageEffect='fadeIn'
                            />
                          )}
                          <Flex sx={styles.postInfo}>
                            {/* Author */}
                            <ContentText
                              content={text?.[3]}
                              sx={{
                                display: `inline-block`,
                                flexBasis: `full`,
                                fontWeight: `medium`,
                                color: `omegaDark`
                              }}
                            />
                            {/* Info */}
                            <Flex sx={{ alignItems: `center`, color: `omega` }}>
                              <ContentText
                                content={text?.[4]}
                                sx={{ display: `inline-block` }}
                                mr='2'
                              />
                              {text?.[5] && (
                                <>
                                  <FaRegClock size={14} />
                                  <ContentText
                                    content={text?.[5]}
                                    sx={{ display: `inline-block`, ml: 2 }}
                                  />
                                </>
                              )}
                            </Flex>
                          </Flex>
                        </Flex>
                      </Box>
                      {buttons && (
                        <>
                          <Divider space={2} />
                          <ContentButtons content={buttons} />
                        </>
                      )}
                    </Box>
                  </Flex>
                </ContentContainer>
              </Box>
            )
          )}
        </Flex>
      </Reveal>
    )}
    {buttons && (
      <>
        <Divider space={3} />
        <ContentButtons content={buttons} />
      </>
    )}
  </Container>
)

export default WithDefaultContent(BlogBlock01)
